<template>
  <div>
    <header-slot no-border-bottom>
    </header-slot>
    <!-- <b-nav card-header pills class="m-0" v-if="!hideFirstTab">
      <b-nav-item
        :to="{ name: $route.meta. }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta."
        > All
      </b-nav-item> 
    </b-nav> -->
    <b-card
      class="border-top-primary border-3 border-table-radius px-0"
      no-body
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import ModalCreateClaimRequest from "@/views/commons/employee-claims/components/modal/ModalCreateEmployeeClaim.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalCreateClaimRequest,
  },
  data() {
    return {
      showModalCreateClaimRequest: false,
    };
  },
  methods: {  
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser", 
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    }, 
  },
};
</script>
